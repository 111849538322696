import React from "react";
import './index.scss'

const Map = () => {

  return (
    <div>
      <img className="map" src='/images/map.png' alt="map"/>
    </div>
  )
}

export default Map
