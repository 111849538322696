import React from "react";
import './Header.scss'

const Header = () => {
  return (
    <div className="header">
      <img src='/icons/icon.svg' alt="" />
      <h2>BACKEND MINER</h2>
    </div>
  )
}

export default Header
