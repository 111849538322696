export const minerStatus = {
  0: 'Idle',
  1: 'Traveling',
  2: 'Mining',
  3: 'Transfering'
}

export const asteroidStatus = {
  0: 'Depleted',
  1: 'Has Minerals'
}
